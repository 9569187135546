import React from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { Banner, Layouts, TextBanner } from '../components/components';
import css from './health-and-nutrition.module.scss';
import arrowSmallBlackSvg from '../Icons/arrowSmallBlack.svg';
import { useStrapiPage } from '../lib/hooks/useStrapiPage';
import { StrapiBlocksName, StrapiMainPagesNames } from '../lib/constants/strapi';
import { replaceStrapiComponentName } from '../lib/utils';
import { TextBannerProps } from '../components/TextBanner/TextBanner';

const HealthAndNutrition = () => {
  const { IndexLayout, Page } = Layouts;
  const { currentPage } = useStrapiPage(StrapiMainPagesNames.healthAndNutritionPage);

  const { t } = useTranslation();

  const data = [
    {
      title: 'Похудеть и проработать проблемные зоны',
      subtitle: 'Онлайн-программы здорового похудения',
      url: '/slimming-school',
      img: '/uploads/img_5_ca8b53edf0.png',
    },
    {
      title: 'Сделать тело подтянутым и красивым',
      subtitle: 'Комплексы видео-тренировок для дома',
      url: '/training-complex',
      img: '/uploads/img_4_5384a8c069.png',
    },
    {
      title: 'Стать нутрициологом для себя и других',
      subtitle: 'Онлайн-курс по здоровому питанию',
      url: '/academy-of-nutrition',
      img: '/uploads/img_3_49398d9001.png',
    },
  ];

  return (
    <IndexLayout>
      <Page>
        <div className={css.wrapper}>
          <div className={css.body}>
            {currentPage && currentPage.blocks && (
              currentPage.blocks.map((component, index) => {
                switch (replaceStrapiComponentName(component.__component)) {
                  case StrapiBlocksName.banner:
                    return (
                      <div className={css.bannerWrapper}>
                        <Banner banner={component.Slide[0]}/>
                      </div>
                    )
                }
              }))}
            <div className={css.headerText}>{t('shell:slimingSchool.choose')}</div>
            <div className={css.content}>
              {data.map((item, idx) => (
                <div className={css.section} key={idx}>
                  <div>
                    <div className={css.image}>
                      <img src={`${process.env.STRAPI_API_URL}${item.img}`} alt="" />
                    </div>
                    <div className={css.title}>{item.title}</div>
                    <div className={css.subtitle}>{item.subtitle}</div>
                  </div>

                  <Link to={`${item.url}`}>
                    <div className={css.button}>
                      Подробнее
                      <img src={arrowSmallBlackSvg} alt="arrow" />
                    </div>
                  </Link>
                </div>
              ))}
            </div>


            {/*<div style={{ display: 'none' }} className={css.big}>*/}
            {/*  <div>*/}
            {/*    <img src={`${process.env.STRAPI_API_URL}/uploads/med_7e9025024f.png`} alt="img" />*/}
            {/*  </div>*/}
            {/*  <div className={css.text}>*/}
            {/*    <div className={css.title}>Выбрать оборудование для фитнеса</div>*/}
            {/*    <div className={css.subtitle}>Профессиональные товары для безопасных тренировок #MEDUSHKINAFIT</div>*/}
            {/*    <Link to="/fitness-equipment">*/}
            {/*      <div className={css.button} style={{ color: 'white' }}>*/}
            {/*        Подробнее*/}
            {/*        <img src={arrowSmallBlackSvg} alt="arrow" />*/}
            {/*      </div>*/}
            {/*    </Link>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {currentPage && currentPage.blocks && (
              currentPage.blocks.map((component) => {
                switch (replaceStrapiComponentName(component!.__component)) {
                  case StrapiBlocksName.textBanner:
                    return (
                      <TextBanner banner={component as TextBannerProps}/>
                    )
                }
              }))}
          </div>
        </div>
      </Page>
    </IndexLayout>
  );
};

export default HealthAndNutrition;
