export enum StrapiMainPagesNames {
  about = 'about',
  fitnessEquipment = 'fitness-equipment',
  contacts = 'contacts',
  faq = 'faq',
  reviews = 'reviews',
  partners = 'partners',
  main = 'slimming-school',
  faceFitness = 'face-fitness',
  academyOfNutrition = 'academy-of-nutrition',
  academyOfPromotion = 'academy-of-promotion',
  trainingComplex = 'training-complex',
  healthAndNutritionPage = 'health-and-nutrition',
}

export enum StrapiBlocksType {
  promo = 'promo',
  study = 'study',
  circularList = 'circularList',
  author = 'author',
  achiviementsSlider = 'achiviementsSlider',
  freeProducts = 'freeProducts',
  news = 'news',
  descriptionBlock = 'descriptionBlock',
  questionList = 'questionList',
  reviewsSelect = 'reviewsSelect',
  reviewSlider = 'reviewSlider',
  reviewImageSlider = 'reviewImageSlider',
}

export enum TaplinkBlocksType {
  taplinkNav = 'taplinkNav',
}

export enum StrapiTaplinkBlockName {
  taplinkPromo = 'promo',
  taplinkLinksList = 'taplink-links-list',
  taplinkCheckList = 'taplink-checklist',
  taplinkContact = 'taplink-contact',
  taplinkVideoBlock = 'taplink-video-block',
  taplinkVideoSlider = 'taplink-video-slider',
  taplinkSubscribe = 'taplink-subscribe',
  taplinkFooter = 'taplink-footer',
  taplinkReviews = 'taplink-reviews',
  taplinkPostSlider = 'taplink-post-slider',
}

export enum StrapiBlocksName {
  banner = 'banner',
  carouseBanner = 'carousel-banner',
  textBanner = 'text-banner',
  aboutSplash = 'about-splash',
  aboutList = 'about-list',
  fitnessEquipmentSplash = 'fitness-equipment-splash',
  howItWork = 'how-it-work',
  blockWithCircle = 'block-with-circle',
  getIt = 'get-it',
  splashWithouCircle = 'splash-withou-circle',
  smallItemsList = 'small-items-list',
  reviewSlider = 'review-slider',
  blockWithCircleReview = 'block-with-circle-review',
  questionList = 'question-list',
  contactsList = 'contacts-list',
  partnersSplash = 'partners-splash',
  reviewImageSlide = 'review-image-slide',
  partnersInfo = 'partners-info',
  defaultSplash = 'promo',
  study = 'study',
  circularList = 'circular-list',
  author = 'author',
  achiviementsSlider = 'achiviements-slider',
  freeProducts = 'free-products',
  news = 'news',
  projectSplash = 'project-splash',
  bulletList = 'bullet-list',
  projectAuthor = 'project-author',
  numberedList = 'numbered-list',
  paymentBlock = 'payment-block',
  pictureWithText = 'picture-with-text',
  buttonBlock = 'button-block',
  beforeAfterSlider = 'before-after-slider',
  projectItemsList = 'project-items-list',
  projectSelect = 'project-select',
  projectSmallList = 'project-small-list',
  projectResult = 'project-result',
  projectCartList = 'project-cart-list',
  projectPictureWithText = 'project-picture-with-text',
  projectBlockWithCircleImage = 'project-block-with-circle-image',
  projectsList = 'projects-list',
  descriptionBlock = 'description-block',
  cartList = 'cart-list',
  itemsOnLine = 'items-on-line',
  projectBeforeAfter = 'projects-before-after',
  projectContraindication = 'project-contraindications',
  projectFlatAuthor = 'project-flat-author',
  projectThreeElements = 'project-three-elements',
  projectCourators = 'project-courators',
  projectHowToStart = 'project-how-to-start',
  porjectFlatPayment = 'porject-flat-payment',
  projectDiet = 'project-diet',
  projectDietVideo = 'project-diet-video',
  productSpalsh = 'product-spalsh',
  ordering = 'ordering',
  waysOfPayment = 'ways-of-payment',
  refundProblem = 'refund-problem',
  productBulletList = 'product-bullet-list',
  productVideoBlock = 'product-video-block',
  productCreator = 'product-creator',
  productConnect = 'product-connect',
}

export enum QuestionCategory {
  slimmingProjects = 'slimmingProjects',
  generalIssues = 'generalIssues',
}

export enum ProjectCategories {
  slimming = 'slimming',
  fitnessEquipment = 'fitnessEquipment',
  faceFitness = 'faceFitness',
  academyOfNutrition = 'academyOfNutrition',
  academyOfPromotion = 'academyOfPromotion',
  trainingComplex = 'trainingComplex',
}
